
import { defineComponent } from "vue";
import { mapGetters, mapActions, mapState } from "vuex";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import ProgressSpinner from "primevue/progressspinner";
import Calendar from "primevue/calendar";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import SpeedDial from "primevue/speeddial";
import Dropdown from "primevue/dropdown";
import Tooltip from "primevue/tooltip";
import Badge from "primevue/badge";
import EmailFileDialog from "@/components/UI/EmailFileDialog.vue";

import InvoiceService from "@/services/InvoiceService";
import Utils from "@/utility/utils";
import GetAccountsReceivableRequest, {
  AccountsReceivableAgingTypes,
  ARSortTypes,
} from "@/types/services/accountsReceivable";
import { NotificationType } from "@/types/notification";
import { useFetchArRecords } from "@/composables/AR/useFetchAccountReceivables";
import ARService from "@/services/ARService";

const arService = new ARService();

export default defineComponent({
  components: {
    DataTable,
    Column,
    Button,
    InputText,
    Calendar,
    Row,
    ColumnGroup,
    // SpeedDial,
    Badge,
    ProgressSpinner,
    EmailFileDialog,
    Dropdown,
  },
  directives: {
    tooltip: Tooltip,
  },
  props: {
    tableName: {
      type: String,
      default: "ReceivablesTable",
    },
    isCustomTab: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Object,
      default: () => ({}) as any,
    },
  },
  inject: ["accountsReceivableAgingType"],
  setup() {
    const { fetchArRecords } = useFetchArRecords(arService);
    return { fetchArRecords };
  },
  data() {
    return {
      showEmailInvoiceDialog: false,
      currentInvoiceId: "",
      first: 0,
      rowsPerPage: 5,
      dateRange: null as any,
      receivables: [] as any,
      filters: {
        ar_id: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        cust_name: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        status: { value: [], matchMode: FilterMatchMode.IN },
        type: { value: [], matchMode: FilterMatchMode.IN },
      },
      types: [
        { name: "Credit Memo", code: "CM" },
        { name: "Debit Memo", code: "DM" },
        { name: "Invoice", code: "IN" },
        { name: "On Account", code: "OA" },
      ],
      stati: [
        { name: "Outstanding", code: "O" },
        { name: "Paid", code: "P" },
        { name: "Payment Pending", code: "Y" },
      ],
      hoverPayBottonIndex: -1,
      hover: [],
      invoiceService: new InvoiceService(),
      selectedStatus: "",
      selectedType: "",
      agingType: this.accountsReceivableAgingType,
      isLoading: false,
    };
  },
  async created() {
    this.dateRange = this.range ? this.range.range : null;

    this.getReceivables();

    this.rowsPerPage = this.itemsPerPage;
  },
  computed: {
    ...mapState(["accountingReceivables"]),
    ...mapGetters({
      getClient: "session/getClient",
      getReceivablesByLabel: "accountingReceivables/getReceivablesByLabel",
      getIsTabLoading: "accountingReceivables/getIsTabLoading",
      itemsPerPage: "accountingReceivables/getItemsPerPage",
      getInvoice: "accountingReceivables/getInvoice",
      loadingPDFInvoices: "accountingReceivables/getLoadingInvoices",
      invoicesToPay: "invoice/getInvoicesToPay",
    }),
    getTotalBalance(): string {
      let total = 0.0;
      if (!this.receivables || !this.receivables.length)
        return this.formatCurrency(total);
      this.receivables.forEach((invoice: any) => {
        total += parseFloat(invoice.balance ?? "0.00");
      });
      return this.formatCurrency(total);
    },
  },
  methods: {
    ...mapActions({
      addIsLoading: "accountingReceivables/addIsLoading",
      updateCustomDateRange: "accountingReceivables/updateCustomDateRange",
      addPDFLoading: "accountingReceivables/addIdLoading",
      addPDFInvoice: "accountingReceivables/addPDFInvoice",
      removeIdLoading: "accountingReceivables/removeIdLoading",
      addNotification: "notification/add",
      postInvoiceToPay: "invoice/addInvoiceToPay",
      removeInvoiceToPay: "invoice/removeInvoiceToPay",
    }),
    async handleClearFilterByStatus(): Promise<void> {
      this.selectedStatus = "";
      this.getReceivables(true);
    },
    async handleClearFilterByType(): Promise<void> {
      this.selectedType = "";
      this.getReceivables(true);
    },
    async handleFilter(): Promise<void> {
      this.getReceivables(true);
    },
    handleHideCalendar(event: any) {
      if (!event[0] || event[1] === null) return;
      this.updateCustomDateRange({
        label: this.range ? this.range.label : "",
        range: this.dateRange,
      });

      this.getReceivables(true);
    },
    formatCurrency(value: number) {
      let number = typeof value === "string" ? parseFloat(value) : value;
      return `$${number.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    },
    onRowSelect(data: any) {
      console.log("row selected", data);
    },
    getSpeedDialActions(data: any) {
      return [
        // {
        //   label: 'Config',
        //   icon: 'pi pi-cog',
        //   command: () => {
        //     console.log('config', data);
        //   }
        // },
        // {
        //   label: 'Edit',
        //   icon: 'pi pi-pencil',
        //   command: () => {
        //    console.log('edit', data);
        //   }
        // },
        {
          label: "Send",
          icon: "pi pi-envelope",
          command: () => {
            this.showEmailModal(data.ar_id);
          },
        },
        {
          label: "Print",
          icon: this.invoiceIcon(data.ar_id),
          command: () => {
            this.fetchPDFInvoice(data.ar_id);
          },
        },
      ];
    },
    getTypeTitle(type: string): string {
      return this.types.find((typ: any) => typ.code === type)?.name ?? "";
    },

    onPage(event: any): void {
      if (
        (event.page === event.pageCount ||
          event.page === event.pageCount - 1 ||
          event.page === event.pageCount - 2) &&
        this.receivables.length % 100 === 0
      ) {
        this.getReceivables();
      }
    },

    invoiceIcon(invoiceId: string) {
      let downloaded = this.getInvoice;
      let downloading = this.loadingPDFInvoices.includes(invoiceId);
      return {
        "pi pi-download":
          downloaded.find((i: any) => i.invoice_num === invoiceId) ===
            undefined && !downloading,
        "pi pi-spin pi-spinner": downloading,
        "pi pi-file-pdf":
          downloaded.find((i: any) => i.invoice_num === invoiceId) !==
            undefined && !downloading,
      };
    },

    async getReceivables(isReset = false) {
      this.isLoading = true;
      if (isReset) {
        this.receivables = [];
      }
      const rangeStart = this.receivables.length + 1;
      const rangeEnd = this.receivables.length + 100;
      const request = {
        statuses: [this.selectedStatus],
        Client: this.getClient,
        types: [this.selectedType],
        correls: "cust_name",
        rangeStart,
        rangeEnd,
      } as Partial<GetAccountsReceivableRequest>;

      let dateStart = undefined;
      let dateEnd = undefined;
      if (this.dateRange == null || this.dateRange.length === 0) {
        dateStart = dateEnd = undefined;
      } else if (this.dateRange[0] && this.dateRange[1]) {
        dateStart = Utils.formatDate(this.dateRange[0]);
        dateEnd = Utils.formatDate(this.dateRange[1]);
      } else if (this.dateRange[0]) {
        dateStart = Utils.formatDate(this.dateRange[0]);
      } else if (this.dateRange[1]) {
        dateEnd = Utils.formatDate(this.dateRange[1]);
      }

      if ((this.agingType as any) === AccountsReceivableAgingTypes.DueDate) {
        request.dueDateStart = dateStart;
        request.dueDateEnd = dateEnd;
        request.sortBy = ARSortTypes.DueDate;
      } else if (
        (this.agingType as any) === AccountsReceivableAgingTypes.RegisterDate
      ) {
        request.registerDateStart = dateStart;
        request.registerDateEnd = dateEnd;
        request.sortBy = ARSortTypes.RegisterDate;
      } else {
        request.invoiceDateStart = dateStart;
        request.invoiceDateEnd = dateEnd;
        request.sortBy = ARSortTypes.InvoiceDate;
      }
      try {
        const resp = await this.fetchArRecords(request);
        this.receivables.push(...resp.ar_items);
      } catch (error) {
        this.addNotification({
          message: `Failed to fetch receivables. Please try again.`,
          type: NotificationType.ERROR,
        });
      } finally {
        this.isLoading = false;
      }
    },

    fetchPDFInvoice(invoiceId: string) {
      const pdf = this.getInvoice?.find(
        (invoice: any) => invoice.invoice_num === invoiceId,
      );

      if (pdf !== undefined) {
        window.open(pdf.pdf, "_blank");
      } else {
        this.addPDFLoading(invoiceId);

        this.invoiceService
          .getInvoice(invoiceId)
          .then((response: any) => {
            const bufferArray = Utils.base64ToArrayBuffer(response);
            const blobStore = new Blob([bufferArray], {
              type: "application/pdf",
            });
            const data = window.URL.createObjectURL(blobStore);
            this.addPDFInvoice({ invoice_num: invoiceId, pdf: data });

            this.removeIdLoading(invoiceId);

            window.open(data, "_blank");
          })
          .catch(() => {
            this.addNotification({
              message: `Failed to download invoice preview. Please try again.`,
              type: NotificationType.ERROR,
            });

            this.removeIdLoading(invoiceId);
          });
      }
    },
    showEmailModal(id: string) {
      this.showEmailInvoiceDialog = true;
      this.currentInvoiceId = id;
    },
    sendEmail(data: any) {
      this.invoiceService
        .getInvoice(this.currentInvoiceId, data)
        .then((response: any) => {
          if (response === "success") {
            this.addNotification({
              message: `Invoice #${this.currentInvoiceId} has been emailed successfully`,
              type: NotificationType.SUCCESS,
            });
          } else {
            this.addNotification({
              message: `Invoice was not sent`,
              type: NotificationType.ERROR,
            });
          }
        })
        .catch((error) => {
          this.addNotification({
            message: `Invoice could not be sent: ${error}`,
            type: NotificationType.ERROR,
          });
        })
        .catch((error) => {
          this.addNotification({
            message: `Invoice could not be sent: ${error}`,
            type: "error",
          });
        });
    },
    existsInPayArray(invoice: any) {
      const found = this.invoicesToPay.find((inv: any) => {
        return inv.arId == invoice.ar_id;
      });
      if (found) return true;

      return false;
    },
    addInvoiceToPay(invoice: any) {
      this.postInvoiceToPay({
        arId: invoice.ar_id,
        balance: parseFloat(invoice.balance),
        dueDate: invoice.due_date,
        cust: invoice.cust,
      });

      this.addNotification({
        message: `Invoice #${invoice.ar_id} added to payment.`,
        type: NotificationType.SUCCESS,
      });
    },
    removeInvoice(data: any): void {
      this.removeInvoiceToPay({
        arId: data.ar_id,
        balance: parseFloat(data.balance),
      });

      this.addNotification({
        message: `Invoice #${data.ar_id} removed from payment.`,
        type: NotificationType.WARN,
      });
    },
  },
  watch: {
    range(val: any) {
      this.dateRange = val.range;
    },
  },
});
